<template>
  <div class="elv-reconciliation-task-data-sets-content-detail-content">
    <div class="elv-reconciliation-task-data-sets-content-detail-content-table">
      <CustomTable
        border
        stripe
        :tableData="props.tableList"
        :tableHeader="props.tableHeader"
        :isShowPagination="false"
        :empty-text="t('reconciliation.dataNotAdded')"
      >
        <template #index="{ index }">
          <span class="elv-data-sources-index">{{ index + 1 }}</span>
        </template>
        <template #name="{ data }">
          <div class="elv-reconciliation-task-data-sets-table-item-data">
            <span class="elv-reconciliation-task-data-sets-table-item-data-name">{{ data.name }}</span>
            <span
              v-if="data?.reconciliationDataSetId === '0'"
              class="elv-reconciliation-task-data-sets-table-item-data-journal"
              >{{ t('reconciliation.cannotBeRemoved') }}</span
            >
            <div v-if="data.conditions" class="elv-reconciliation-task-data-sets-table-item-data-conditions">
              <div
                v-for="conditionItem in currentConditionsList(data.conditions)"
                :key="conditionItem.reconciliationDataSetId"
                class="elv-reconciliation-task-data-sets-table-item-data-conditions-item"
              >
                <span class="elv-reconciliation-task-data-sets-table-item-data-conditions-item-type"
                  >{{ currentDataSetItemConditionInfo(conditionItem).type }}
                  {{
                    ['CHART_OF_ACCOUNT', 'IS_CATEGORIED'].includes(conditionItem.type)
                      ? t('common.is')
                      : t('common.in')
                  }}:</span
                >
                <div class="elv-reconciliation-task-data-sets-table-item-data-conditions-item-panel">
                  <div
                    v-for="(detailItem, detailIndex) in currentDataSetItemConditionInfo(conditionItem).info"
                    :key="detailIndex"
                    class="elv-reconciliation-task-data-sets-table-item-data-conditions-item-content"
                  >
                    <img v-if="detailItem?.icon" :src="detailItem.icon" alt="" />
                    <span>{{ detailItem.label }}</span>
                    <span class="splice-symbol">、</span>
                  </div>
                </div>
              </div>

              <div
                v-if="resetShowAuxiliaryInfo(data.conditions)?.length"
                class="elv-reconciliation-task-data-sets-table-item-data-conditions-item"
              >
                <span class="elv-reconciliation-task-data-sets-table-item-data-conditions-item-type"
                  >{{ t('report.auxiliaryCode') }} {{ t('common.in') }}:</span
                >
                <div class="elv-reconciliation-task-data-sets-table-item-data-conditions-item-panel">
                  <div
                    v-for="(detailItem, detailIndex) in resetShowAuxiliaryInfo(data.conditions)"
                    :key="detailIndex"
                    class="elv-reconciliation-task-data-sets-table-item-data-conditions-item-content"
                  >
                    <template v-if="detailItem.label !== 'operator=EXCLUDES'">
                      <i18n-t
                        keypath="reconciliation.childInParent"
                        tag="p"
                        class="elv-reconciliation-task-data-sets-table-item-data-conditions-item-content-auxiliary"
                        scope="global"
                      >
                        <template #child>
                          <span class="child-label">{{ detailItem.key }}</span>
                        </template>
                        <template #parent>
                          <span class="parent-label">{{ detailItem.label }}</span>
                        </template>
                      </i18n-t>
                      <span class="splice-symbol">，</span>
                    </template>
                    <template v-else>
                      <p class="elv-reconciliation-task-data-sets-table-item-data-conditions-item-content-auxiliary">
                        <span class="child-label">{{ detailItem.key }}&nbsp;&nbsp;</span>
                        <span class="parent-label">{{ t('common.Is empty') }}</span>
                      </p>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #number="{ data }">
          <TextCell
            :text="formatNumberToSignificantDigits(data.reconciliationDataCount, 2, '', false)"
            :weight="500"
            size="13px"
            fontFamily="Barlow"
            align="right"
          />
        </template>
        <template #amount="{ data }">
          <div class="elv-reconciliation-task-data-sets-table-item-amount">
            <IconWithTitleCell
              v-if="data.reconciliationDataCurrencyList?.length && data.reconciliationDataCurrencyList?.length === 1"
              :icon="data.reconciliationDataCurrencyList[0]?.underlyingCurrency?.logo || defaultImg"
              :titleDesc="data.reconciliationDataCurrencyList[0]?.amountTotal || ''"
              :title="
                formatNumberToSignificantDigits(
                  Number(data.reconciliationDataCurrencyList[0].amountTotal),
                  2,
                  '',
                  false
                )
              "
              titleFontFamily="Barlow"
              :titleWeight="500"
              titleSize="13px"
            />
            <div v-else class="elv-reconciliation-task-data-sets-table-item-amount-logo">
              <AmountIconMoreShow :imgList="data.reconciliationDataCurrencyList" />
            </div>
            <TextCell
              :text="
                formatNumberToSignificantDigits(
                  Number(data.amountFCTotal),
                  2,
                  `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                )
              "
              :titleDesc="`${data.amountFCTotal}` || ''"
              :weight="400"
              size="11px"
              fontFamily="Barlow"
              align="right"
            />
          </div>
        </template>
        <template #detailTag="{ data }">
          <Popover
            trigger="hover"
            placement="bottomLeft"
            overlay-class-name="elv-transactions-table-row__operating-popover"
            :offset="2"
          >
            <SvgIcon
              v-if="data?.reconciliationDataSetId !== '0'"
              width="14"
              height="14"
              name="more-dian"
              :fill="'#838d95'"
            />

            <template #content>
              <div
                class="elv-reconciliation-table-row__operating-item"
                :style="{ display: 'flex', 'align-items': 'center' }"
                @click="editDataSetSourceInfo(data)"
              >
                <SvgIcon name="sources-edit" width="18" height="18" fill="#D0D4D9" />
                {{ t('button.edit') }}
              </div>
              <div
                class="elv-reconciliation-table-row__operating-item"
                :style="{ display: 'flex', 'align-items': 'center' }"
                @click="deleteDataSourceInfo(data)"
              >
                <SvgIcon name="sources-delete" width="18" height="18" fill="#D0D4D9" />
                {{ t('button.delete') }}
              </div>
            </template>
          </Popover>
        </template>
      </CustomTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Popover } from 'ant-design-vue'
import { transactionType } from '@/config'
import { flatMap, isEmpty } from 'lodash-es'
import defaultImg from '@/assets/img/default-logo.png'
import { useEntityStore } from '@/stores/modules/entity'
import AmountIconMoreShow from '../AmountIconMoreShow.vue'
import { formatNumberToSignificantDigits } from '@/lib/utils'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import CustomTable from '@/pages/Financials/Project/components/CustomTable.vue'
import IconWithTitleCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'
import { ReconciliationTaskDataSetEnum, ReconciliationTaskDataSetItemDetailType } from '#/ReconciliationTypes'

const props = defineProps({
  tableList: {
    type: Array,
    default: () => Array<any>
  },
  tableHeader: {
    type: Array<any>,
    default: () => {
      return []
    }
  },
  positionType: {
    type: String,
    default: 'LEFT'
  }
})

type DataSourceDetailType = {
  icon: string
  label: string
}

const emits = defineEmits(['onEditDataSetSource', 'onDeleteDataSetSource'])

const { t } = useI18n()
const entityStore = useEntityStore()
const reconciliationStore = useReconciliationStore()
const { entityDetail } = storeToRefs(entityStore)
const { reconciliationTaskDetail } = reactive(reconciliationStore)

const dataSideType = computed(() => {
  return props.positionType === 'LEFT' ? reconciliationTaskDetail.dataSetTypeA : reconciliationTaskDetail.dataSetTypeB
})

const currentDataSetItemConditionInfo = computed(() => (condition: any) => {
  const conditionInfo = { type: '', info: [] as Array<DataSourceDetailType> }
  switch (condition.type) {
    case 'TRANSACTION_TYPE':
      conditionInfo.type = t('common.type')
      const transactionTypeList = flatMap(transactionType, 'list')
      conditionInfo.info = condition.value.map((item: string) => {
        const currentType = transactionTypeList.find((type) => type.value === item)
        return {
          label: currentType ? t(currentType.label) : '',
          icon: ''
        }
      })
      break
    case 'CURRENCY':
      conditionInfo.type =
        dataSideType.value === ReconciliationTaskDataSetEnum.TRANSACTION
          ? t('report.Base Asset')
          : t('report.originalCurrency')
      conditionInfo.info = condition?.value.map((item: any) => {
        const currentCurrency = condition?.underlyingCurrencyList.find((currency: any) => currency.showSymbol === item)
        return {
          label: item || '',
          icon: currentCurrency?.logo || ''
        }
      })
      break
    case 'ENTITY_ACCOUNT':
      conditionInfo.type = t('common.account')
      conditionInfo.info = condition.entityAccountList.map((item: any) => {
        return {
          label: item?.name || '',
          icon: ''
        }
      })
      break
    case 'PLATFORM':
      conditionInfo.type = t('common.platform')
      conditionInfo.info = condition.platformList.map((item: any) => {
        return {
          label: item?.name || '',
          icon: item?.logo || ''
        }
      })
      break
    case 'CHART_OF_ACCOUNT':
      conditionInfo.type = t('reconciliation.coa')
      conditionInfo.info = condition.chartOfAccount?.name
        ? [
            {
              label: condition.chartOfAccount?.name,
              icon: ''
            }
          ]
        : []
      break
    case 'BALANCE_TYPE':
      conditionInfo.type = t('report.Dr/Cr')
      const currentDrCr = condition.value.map((item: string) => {
        return item.toLowerCase().replace(/\b[a-z]/g, (match) => {
          return match.toUpperCase()
        })
      })
      conditionInfo.info = [{ label: currentDrCr.join(' / '), icon: '' }]
      break
    case 'IS_CATEGORIED':
      conditionInfo.type = t('common.categorizing')
      conditionInfo.info = [
        {
          label:
            dataSideType.value === ReconciliationTaskDataSetEnum.TRANSACTION
              ? t('title.notCategorized')
              : t('title.notCreatedByCategorizing'),
          icon: ''
        }
      ]
      break
    default:
      break
  }
  return conditionInfo
})

const filterConditionTypeNotAuxiliary = (dataSetList: Array<any>) => {
  return dataSetList.filter(
    (item) => (item.type !== 'AUXILIARY' && !isEmpty(item.value)) || item.type === 'IS_CATEGORIED'
  )
}

const currentConditionsList = computed(() => (dataSetList: Array<any>) => {
  return dataSideType.value === ReconciliationTaskDataSetEnum.TRANSACTION
    ? dataSetList
    : filterConditionTypeNotAuxiliary(dataSetList)
})

const resetShowAuxiliaryInfo = (dataSetList: Array<any>) => {
  const auxiliaryList = dataSetList.filter((setItem) => setItem.type === 'AUXILIARY')
  if (!auxiliaryList?.length) return []
  if (auxiliaryList?.[0]?.operator === 'EXCLUDES') console.log(auxiliaryList, 'auxiliaryList')
  const conditionInfo = auxiliaryList.map((item) => {
    const key = item.auxiliaryType.name
    let label = ''
    if (item?.auxiliaryType?.type === 'COUNTERPARTY') {
      label = item.counterpartyList.map((cp: any) => cp.name).join('、')
    } else if (item?.auxiliaryType?.type === 'ENTITY_ACCOUNT') {
      label = item.entityAccountList.map((account: any) => account.name).join('、')
    } else if (item?.auxiliaryType?.type === 'ITEM') {
      label = item.auxiliaryItems.map((aux: any) => aux.value).join('、')
    }

    if (item?.operator === 'EXCLUDES') {
      label = 'operator=EXCLUDES'
    }
    return { label, key }
  })
  return conditionInfo
}

const editDataSetSourceInfo = (data: ReconciliationTaskDataSetItemDetailType) => {
  emits('onEditDataSetSource', { data, type: dataSideType.value })
}

/**
 * 打开删除数据集确认弹窗
 */
const deleteDataSourceInfo = (data: ReconciliationTaskDataSetItemDetailType) => {
  emits('onDeleteDataSetSource', { data })
}
</script>

<style lang="scss" scoped>
.elv-reconciliation-task-data-sets-content-detail-content {
  width: calc(50% - 8px);
  position: relative;

  .elv-reconciliation-task-data-sets-content-detail-content-table {
    position: relative;

    :deep(.el-table__empty-block) {
      height: 255px !important;

      .el-table__empty-text {
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-weight: 400;
        color: #dde1e6;
      }
    }
  }
}
</style>
