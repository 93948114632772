<template>
  <div class="elv-reconciliation-date-sets-amount-icon-more-show">
    <img
      v-for="(item, index) in imgLogoList"
      :key="index"
      :src="item"
      alt=""
      :onerror="useDefaultImage"
      :style="{ width: props.imgSize, height: props.imgSize, zIndex: imgLogoList.length - index }"
    />
    <span v-if="isMoreThanOneImg">{{ isMoreThanOneImg }}</span>
  </div>
</template>

<script setup lang="ts">
import defaultImg from '@/assets/img/default-logo.png'

const props = defineProps({
  imgList: {
    type: Array,
    default: () => []
  },
  imgSize: {
    type: String,
    default: '12px'
  }
})

const imgLogoList = computed(() => {
  const imgList = props.imgList.map((item: any) => item?.underlyingCurrency?.logo || defaultImg)
  if (imgList.length > 5) {
    return imgList.slice(0, 5)
  }
  return imgList
})

const isMoreThanOneImg = computed(() => {
  if (props.imgList?.length > 5) {
    return `+${props.imgList.length - 5}`
  }
  return ''
})

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}
</script>

<style lang="scss" scoped>
.elv-reconciliation-date-sets-amount-icon-more-show {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  img {
    margin-left: -2px;
    border-radius: 50%;
  }

  span {
    height: 12px;
    padding: 0px 4px;
    border-radius: 20px;
    background-color: #edf0f3;
    border: 0.5px solid #d0d4d9;
    font-family: 'Barlow';
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: #838d95;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2px;
  }
}
</style>
