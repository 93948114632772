<template>
  <div v-loading="loading || reconciliationStore.detailLoading" class="elv-reconciliation-task-data-sets-page">
    <el-scrollbar class="elv-reconciliation-task-data-sets-content">
      <div class="elv-reconciliation-task-data-sets-content-title">
        <div class="elv-reconciliation-task-data-sets-content-detail-content-header">
          <div class="elv-reconciliation-task-data-sets-content-detail-content-header-title">
            <p>
              <span>{{ reconciliationTaskDetail.dataSetNameA }}</span
              ><SvgIcon
                name="sources-edit"
                width="14"
                height="14"
                :fill="'#838D95'"
                @click="editDataSourceName('LEFT')"
              />
            </p>
            <p>
              {{ t('project.dataType') }}:{{
                t(reconciliationTaskType(reconciliationTaskDetail.dataSetTypeA)?.label || '')
              }}
            </p>
          </div>
          <elv-button
            height="32"
            width="96"
            round
            plain
            type="primary"
            @click="addDataSource(reconciliationTaskDetail.dataSetTypeA)"
            ><SvgIcon name="source-add" width="16" height="16" />{{ t('project.addData') }}</elv-button
          >
        </div>
        <div class="elv-reconciliation-task-data-sets-content-detail-content-header">
          <div class="elv-reconciliation-task-data-sets-content-detail-content-header-title">
            <p>
              <span>{{ reconciliationTaskDetail.dataSetNameB }}</span
              ><SvgIcon
                name="sources-edit"
                width="14"
                height="14"
                :fill="'#838D95'"
                @click="editDataSourceName('RIGHT')"
              />
            </p>
            <p>
              {{ t('project.dataType') }}:{{
                t(reconciliationTaskType(reconciliationTaskDetail.dataSetTypeB)?.label || '')
              }}
            </p>
          </div>
          <elv-button
            height="32"
            width="96"
            round
            plain
            type="primary"
            @click="addDataSource(reconciliationTaskDetail.dataSetTypeB)"
            ><SvgIcon name="source-add" width="16" height="16" />{{ t('project.addData') }}</elv-button
          >
        </div>
      </div>
      <div class="elv-reconciliation-task-data-sets-content-detail">
        <DateSetSideTable
          :tableList="reconciliationTaskLeftDataSets"
          :tableHeader="tableHeader"
          positionType="LEFT"
          @onEditDataSetSource="editDataSourceInfo"
          @onDeleteDataSetSource="deleteDataSourceInfo"
        />
        <DateSetSideTable
          :tableList="reconciliationTaskRightDataSets"
          :tableHeader="tableHeader"
          positionType="RIGHT"
          @onEditDataSetSource="editDataSourceInfo"
          @onDeleteDataSetSource="deleteDataSourceInfo"
        />
      </div>
      <div class="elv-reconciliation-task-data-sets-content-balance">
        <p class="elv-reconciliation-task-data-sets-content-balance-title">{{ t('project.changeInBalance') }}</p>
        <p class="elv-reconciliation-task-data-sets-content-balance-decs">{{ t('desc.changeBalanceDifference') }}</p>
        <div
          v-if="reconciliationTaskBalanceList?.length"
          class="elv-reconciliation-task-data-sets-content-balance-table"
        >
          <el-table
            ref="tableListRef"
            :data="reconciliationTaskBalanceList"
            border
            stripe
            class="elv-reconciliation-data-sets-balance-table"
            header-cell-class-name="elv-reconciliation-data-sets-balance-table-header__cell"
            header-row-class-name="elv-reconciliation-data-sets-balance-table-header"
            row-class-name="elv-reconciliation-data-sets-balance-table-row"
            cell-class-name="elv-reconciliation-data-sets-balance-table-row__cell"
          >
            <el-table-column
              type="index"
              width="36"
              align="center"
              class-name="elv-reconciliation-data-sets-balance-table-row__index"
            />
            <el-table-column
              v-for="headerItem in balanceTableHeader"
              :key="headerItem.dataIndex"
              :width="headerItem?.width || undefined"
              :label="headerItem.title"
              :header-align="headerItem?.headerAlign || 'left'"
              class-name="elv-reconciliation-data-sets-balance-table-row__content"
            >
              <template #default="{ row }">
                <TextCell
                  :text="currentRowDataInfo(headerItem, row).label"
                  :titleDesc="currentRowDataInfo(headerItem, row).desc"
                  :weight="500"
                  size="13px"
                  fontFamily="Plus Jakarta Sans"
                  :class="{
                    'small-number': !isEmpty(row) && headerItem.dataIndex !== 'name' && row[headerItem.dataIndex] < 0
                  }"
                  :align="headerItem?.headerAlign || 'left'"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-scrollbar>
    <ElvMessageBox
      ref="editSourceNameMessageBoxRef"
      class="elv-reconciliation-task-data-sets-content-edit-name-message-box"
      :showCancelButton="false"
      :showCloseButton="true"
      :isDisabledConfirmStatus="!currentEditTaskDataSetInfo.name"
      :confirmButtonText="t('button.save')"
      :loading="editNameLoading"
      :title="t('title.dataSetName')"
      @onConfirmEvent="onConfirmSaveSourceName"
    >
      <template #content>
        <el-input
          v-model="currentEditTaskDataSetInfo.name"
          minlength="1"
          maxlength="64"
          :placeholder="t('placeholder.pleaseDataName')"
        />
      </template>
    </ElvMessageBox>
    <ReconciliationTaskStatusChangeDialog
      v-model:show="showDeleteDataSourceDialog"
      v-model:loading="deleteDataLoading"
      :title="t('reconciliation.deleteData')"
      :confirmText="t('button.delete')"
      :cancelText="t('button.cancel')"
      :showAgreeInfo="false"
      @onConfirmChangeStatus="onConfirmDeleteDataSource"
      @onCloseDialog="showDeleteDataSourceDialog = false"
    >
      <template #customContent>
        <p>{{ t('reconciliation.deleteReconciliationTaskDataMsg') }}</p>
      </template>
    </ReconciliationTaskStatusChangeDialog>
    <AddDataSourceDialog
      v-model="showAddDataSourceDialog"
      :currentData="currentEditDataSource"
      :dataSourceType="currentEditDataSourceType"
      @onConfirmSaveDataSource="onConfirmSaveDataSource"
    />
  </div>
</template>

<script setup lang="ts">
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import { cloneDeep, isEmpty } from 'lodash-es'
import ReconciliationApi from '@/api/ReconciliationApi'
import { formatNumberToSignificantDigits } from '@/lib/utils'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import { DataTypeForReconciliation } from '@/config/reconciliation'
import AddDataSourceDialog from './components/AddDataSourceDialog.vue'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import DateSetSideTable from './components/SideTable/DateSetSideTable.vue'
import { ReconciliationTaskDataSetItemDetailType } from '#/ReconciliationTypes'
import ReconciliationTaskStatusChangeDialog from '../components/ReconciliationTaskStatusChangeDialog.vue'

const { t } = useI18n()
const route = useRoute()
const reconciliationStore = useReconciliationStore()

const loading = ref(false)
const editNameLoading = ref(false)
const deleteDataLoading = ref(false)
const currentEditDataSourceType = ref('')
const showAddDataSourceDialog = ref(false)
const showDeleteDataSourceDialog = ref(false)
const reconciliationTaskBalanceList = ref<any[]>([])
const currentEditDataSource = ref({} as ReconciliationTaskDataSetItemDetailType)
const editSourceNameMessageBoxRef = useTemplateRef('editSourceNameMessageBoxRef')

const currentEditTaskDataSetInfo = reactive({
  name: '',
  position: ''
})

const tableHeader: any[] = [
  {
    dataIndex: 'index',
    title: '',
    slotName: 'index',
    width: 36
  },
  {
    dataIndex: 'name',
    title: t('project.dataName'),
    slotName: 'name'
  },
  {
    dataIndex: 'number',
    title: t('common.number'),
    slotName: 'number',
    width: 120,
    headerAlign: 'right'
  },
  {
    dataIndex: 'amount',
    title: t('common.amount'),
    slotName: 'amount',
    width: 120,
    headerAlign: 'right'
  },
  {
    dataIndex: 'detailTag',
    title: '',
    slotName: 'detailTag',
    fixed: 'right',
    width: 36
  }
]

const balanceTableHeader = ref<any[]>([
  {
    dataIndex: 'name',
    title: t('project.nav.changeInBalance'),
    slotName: 'name',
    width: 250
  }
])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const reconciliationTaskId = computed(() => {
  return String(route.params?.reconciliationTaskId as string)
})

const reconciliationTaskDetail = computed(() => {
  return reconciliationStore.reconciliationTaskDetail
})

const reconciliationTaskType = computed(() => (dataType: string) => {
  return DataTypeForReconciliation.find((item) => item.value === dataType)
})

const reconciliationTaskLeftDataSets = computed(() => {
  return reconciliationStore.reconciliationTaskDataSetList.sideAList
})

const reconciliationTaskRightDataSets = computed(() => {
  const list = cloneDeep(reconciliationStore.reconciliationTaskDataSetList.sideBList)
  if (isEmpty(reconciliationStore.reconciliationTaskDataSetList.adjustmentJournal)) {
    return list
  }
  list.push(reconciliationStore.reconciliationTaskDataSetList.adjustmentJournal)
  return list
})

const currentRowDataInfo = computed(() => (headerItem: any, row: any) => {
  const balanceInfo = { label: '-', desc: '' }
  if (!isEmpty(row)) {
    if (headerItem.dataIndex === 'name') {
      balanceInfo.label = row[headerItem.dataIndex]
    } else {
      balanceInfo.label = row[headerItem.dataIndex]
        ? formatNumberToSignificantDigits(Number(row[headerItem.dataIndex]), 2, '', false)
        : '0'
      balanceInfo.desc = `${row[headerItem.dataIndex]}` || ''
    }
  }
  return balanceInfo
})

/**
 * 打开编辑数据集名称弹窗
 */
const editDataSourceName = (position: 'LEFT' | 'RIGHT') => {
  currentEditTaskDataSetInfo.position = position
  if (position === 'LEFT') {
    currentEditTaskDataSetInfo.name = reconciliationTaskDetail.value.dataSetNameA
  } else {
    currentEditTaskDataSetInfo.name = reconciliationTaskDetail.value.dataSetNameB
  }
  editSourceNameMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 初始化TaskBalance数据
 */
const initTaskBalanceData = () => {
  if (!reconciliationStore.reconciliationTaskBalanceList?.currencyList?.length) return
  const list = cloneDeep(reconciliationStore.reconciliationTaskBalanceList?.list)
  if (list && list?.length) {
    const result = list.map((item: any) => {
      const { currencyList, name } = item
      const currencyObj: any = currencyList.reduce((acc: any, curr: any) => {
        return {
          name,
          ...acc,
          [curr.underlyingCurrency?.symbol]: curr.amountTotal
        }
      }, {})
      if (isEmpty(currencyObj)) {
        currencyObj.name = name
      }
      balanceTableHeader.value.forEach((headerItem: any) => {
        if (!Object.prototype.hasOwnProperty.call(currencyObj, headerItem?.dataIndex)) {
          currencyObj[headerItem?.dataIndex] = ''
        }
      })
      return { ...currencyObj }
    })
    reconciliationTaskBalanceList.value = result
  }
}

const getTaskDataSetsData = async () => {
  loading.value = true
  try {
    balanceTableHeader.value = [
      {
        dataIndex: 'name',
        title: t('project.nav.changeInBalance'),
        slotName: 'name',
        width: 250
      }
    ]
    await reconciliationStore.fetchReconciliationTaskBalanceList(entityId.value, reconciliationTaskId.value)
    if (reconciliationStore.reconciliationTaskBalanceList?.list) {
      const currencyHeaderList = reconciliationStore.reconciliationTaskBalanceList?.currencyList?.map((item: any) => {
        return {
          dataIndex: item?.symbol,
          title: item?.showSymbol,
          slotName: item?.symbol,
          width: 140,
          headerAlign: 'right'
        }
      })
      balanceTableHeader.value.push(...currencyHeaderList)
      initTaskBalanceData()
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}

/**
 * 重命名数据集名称
 */
const onConfirmSaveSourceName = async () => {
  try {
    editNameLoading.value = true
    const data = {
      name: reconciliationTaskDetail.value.name,
      fromDatetimeStr: reconciliationTaskDetail.value.fromDatetimeStr,
      toDatetimeStr: reconciliationTaskDetail.value.toDatetimeStr,
      dataSetNameA:
        currentEditTaskDataSetInfo.position === 'LEFT'
          ? currentEditTaskDataSetInfo.name
          : reconciliationTaskDetail.value.dataSetNameA,
      dataSetNameB:
        currentEditTaskDataSetInfo.position === 'LEFT'
          ? reconciliationTaskDetail.value.dataSetNameB
          : currentEditTaskDataSetInfo.name
    }
    await ReconciliationApi.updateReconciliationTask(entityId.value, reconciliationTaskId.value, data)
    await reconciliationStore.fetchReconciliationTaskDetail(entityId.value, reconciliationTaskId.value)
    ElMessage.success(t('common.successfully'))
    editSourceNameMessageBoxRef.value?.onCheckMessageBoxDialog()
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    editNameLoading.value = false
  }
}

/**
 * 删除数据集
 */
const onConfirmDeleteDataSource = async () => {
  deleteDataLoading.value = true
  try {
    await ReconciliationApi.deleteReconciliationTaskDataSet(
      entityId.value,
      reconciliationTaskId.value,
      currentEditDataSource.value?.reconciliationDataSetId
    )
    await reconciliationStore.fetchReconciliationTaskDetail(entityId.value, reconciliationTaskId.value)
    showDeleteDataSourceDialog.value = false
    ElMessage.success(t('common.successfully'))
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    deleteDataLoading.value = false
  }
}

/**
 * 打开编辑数据集信息
 */
const editDataSourceInfo = (editData: any) => {
  const { data, type } = editData
  currentEditDataSource.value = data
  currentEditDataSourceType.value = type
  showAddDataSourceDialog.value = true
}

/**
 * 打开删除数据集确认弹窗
 */
const deleteDataSourceInfo = (deleteData: any) => {
  const { data } = deleteData
  currentEditDataSource.value = data
  showDeleteDataSourceDialog.value = true
}

/**
 * 添加数据集
 */
const addDataSource = (type: string) => {
  currentEditDataSource.value = {} as ReconciliationTaskDataSetItemDetailType
  currentEditDataSourceType.value = type
  showAddDataSourceDialog.value = true
}

/**
 * 添加完数据集之后关闭弹窗，更新页面信息
 */
const onConfirmSaveDataSource = async () => {
  showAddDataSourceDialog.value = false
  try {
    loading.value = true
    await Promise.all([
      reconciliationStore.fetchReconciliationTaskDetail(entityId.value, reconciliationTaskId.value),
      reconciliationStore.fetchReconciliationDataSetList(entityId.value, reconciliationTaskId.value)
    ])
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}

emitter.on('reconciliationTaskDataSetsInit', getTaskDataSetsData)

onMounted(getTaskDataSetsData)

onBeforeUnmount(() => {
  emitter.off('reconciliationTaskDataSetsInit', getTaskDataSetsData)
})
</script>

<style lang="scss">
.elv-reconciliation-task-data-sets-content-edit-name-message-box {
  width: 440px;

  .el-dialog__header {
    justify-content: flex-start;
    padding-left: 24px;
    height: 54px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #edf0f3;

    .elv-confirm-info-header__close {
      bottom: 15px;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 20px;
  }
}

.elv-reconciliation-task-data-sets-content-detail-content-table {
  .elv-reconciliation-task-data-sets-table-item-data {
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-weight: 600;
    color: #0e0f11;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;

    &-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 8px;
    }

    &-journal {
      font-size: 11px;
      font-weight: 400;
      color: #636b75;
      line-height: 16px;
    }

    .elv-reconciliation-task-data-sets-table-item-data-conditions {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      font-weight: 400;
      color: #636b75;
      line-height: 16px;

      &-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        display: flex;
        align-items: flex-start;
        margin-bottom: 4px;

        .elv-reconciliation-task-data-sets-table-item-data-conditions-item-type {
          margin-right: 4px;
        }

        .elv-reconciliation-task-data-sets-table-item-data-conditions-item-panel {
          display: flex;
          flex-wrap: wrap;

          .elv-reconciliation-task-data-sets-table-item-data-conditions-item-content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #0e0f11;

            img {
              width: 12px;
              height: 12px;
              margin-right: 4px;
              border-radius: 50%;
            }

            &:last-child {
              .splice-symbol {
                visibility: hidden;
              }
            }

            &-auxiliary {
              .child-label {
                color: #636b75;
                font-size: 11px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  .elv-reconciliation-task-data-sets-table-item-amount {
    .elv-base-cell-icon-title {
      padding: 0px;
      justify-content: flex-end;

      .elv-base-cell-icon-title-img {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }

      .elv-base-cell-icon-title-title {
        margin-right: 0px;
      }
    }

    .elv-reconciliation-task-data-sets-table-item-amount-logo {
      position: relative;
      margin-bottom: 4px;
    }
  }

  .elv-component-custom-table-panel-content-container {
    height: 100% !important;
  }

  .elv-component-custom-table {
    border-radius: 3px;
    border: 1px solid #ced7e0;
  }

  .elv-data-sources-index {
    font-family: 'Barlow';
    font-size: 11px;
    font-weight: 400;
    color: #aaafb6;
    display: flex;
    justify-content: center;
  }
}

.ant-popover.elv-transactions-table-row__operating-popover {
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  background: #fff;
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 0px rgba(0, 0, 0, 0.05);

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 0px;
    box-shadow: none;
    width: 161px;
    border-radius: 6px;
  }

  .elv-reconciliation-table-row__operating-item {
    display: flex;
    height: 32px;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
    align-items: center;
    align-self: stretch;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: background 0.1s;

    svg {
      margin-right: 8px;
    }

    &.is-disabled {
      color: #aaafb6;
      cursor: not-allowed;
    }

    &:hover {
      background: #f9fafb;
    }
  }
}

.elv-reconciliation-data-sets-balance-table {
  width: 100%;
  box-sizing: border-box;
  // border: 1px solid #ced7e0;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .elv-reconciliation-data-sets-balance-table-header {
    background: #eef4fb;

    .elv-reconciliation-data-sets-balance-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      padding: 0px;

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-reconciliation-data-sets-balance-table-row__cell {
    padding: 0;
    height: 42px;

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
      display: flex;
      align-items: center;

      .elv-base-cell-icon-title {
        padding: 0px;
      }
    }

    &.elv-reconciliation-data-sets-balance-table-row__index .cell {
      color: #aaafb6;
      font-family: 'Barlow';
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.elv-reconciliation-data-sets-balance-table-row__content .cell {
      .elv-base-cell-text {
        width: 100%;

        &.small-number {
          color: #1753eb;
        }
      }
    }

    &.is-right .cell {
      text-align: right;
      justify-content: flex-end;
    }

    &.is-center .cell {
      text-align: center;
      justify-content: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.elv-reconciliation-task-data-sets-page {
  width: 100%;
  height: calc(100% - 128px);
  position: relative;
  display: flex;
  flex-direction: column;
}

.elv-reconciliation-task-data-sets-content {
  height: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 30px;
  position: relative;
  padding-bottom: 20px;

  .elv-reconciliation-task-data-sets-content-title {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 26px;

    .elv-reconciliation-task-data-sets-content-detail-content-header {
      width: calc(50% - 8px);
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;

      button {
        border-color: #c8d6fa;
      }

      .elv-reconciliation-task-data-sets-content-detail-content-header-title {
        margin-bottom: 8px;
        max-width: calc(100% - 110px);

        p {
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-weight: 400;
          color: #838d95;
          line-height: 14px;
          position: relative;

          &:first-child {
            display: flex;
            align-items: flex-start;
            font-size: 16px;
            font-weight: 600;
            color: #0e0f11;
            line-height: 20px;
            width: fit-content;

            span {
              width: calc(100% - 22px);
            }

            svg {
              margin-left: 8px;
              margin-top: 4px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .elv-reconciliation-task-data-sets-content-detail {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .elv-reconciliation-task-data-sets-content-balance {
    font-family: 'Plus Jakarta Sans';
    margin-top: 8px;
    position: relative;

    &-title {
      font-size: 16px;
      font-weight: 600;
      color: #0e0f11;
      line-height: 20px;
      margin-bottom: 4px;
    }

    &-decs {
      font-size: 11px;
      font-weight: 400;
      color: #838d95;
      line-height: 14px;
    }

    &-table {
      margin-top: 8px;
      width: 100%;
    }
  }
}
</style>
