<template>
  <div class="elv-base-cascade-select" :style="{ height: isTagType ? '44px' : '15px' }">
    <div
      v-if="isTagType"
      :class="[
        'elv-base-cascade-select-show-info-tags',
        { 'is-focus': isShowFilterInputDom },
        {
          'elv-base-cascade-select-show-info-tags-selected':
            selectedCascadeOptionList.length && props.isHighLight && !props.isDisabled
        },
        {
          'elv-base-cascade-select-show-info-tags-disabled': props.isDisabled
        }
      ]"
      @click="onChangeCascadeOptionsPopperShowStatus"
    >
      <div v-if="selectedCascadeOptionList.length" class="elv-base-cascade-select-show-info-container">
        <div v-if="isMultipleSelect">
          <el-tag
            v-for="item in showMoreTagSelectList"
            :key="item[props.cascadeOptionValueName]"
            closable
            disable-transitions
            class="elv-base-cascade-select-show-info-item"
            @close="onCloseCascadeItem(item)"
          >
            <span>{{ item[props.cascadeOptionLabelName] ?? '' }}</span>
          </el-tag>
          <el-tooltip
            v-if="isMoreSelected"
            effect="light"
            placement="bottom"
            popper-class="elv-base-cascade-select-show-info-container-more-tip"
            :show-after="500"
          >
            <el-tag class="elv-base-cascade-select-show-info-item-more-count" type="info" disable-transitions
              >+{{ excessSelectCount }}</el-tag
            >
            <template #content>
              <el-tag
                v-for="moreCascadeItem in additionalSelectedItems"
                :key="moreCascadeItem[props.cascadeOptionValueName]"
                closable
                disable-transitions
                type="info"
                effect="light"
                @close="onCloseCascadeItem(moreCascadeItem)"
                >{{ moreCascadeItem[props.cascadeOptionLabelName] }}</el-tag
              >
            </template>
          </el-tooltip>
        </div>
        <div v-else class="elv-base-cascade-select-show-info-container-single-choice">
          {{ selectedCascadeOptionsLabel }}
        </div>
      </div>
      <div v-else class="elv-base-cascade-select-show-info-item-empty">{{ t('common.select') }}...</div>
      <el-icon :size="12" :style="{ transform: rotate, transition: 'transform 0.3s ease-in-out' }">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
          <path
            fill="#a8abb2"
            d="M831.872 340.864 512 652.672 192.128 340.864a30.592 30.592 0 0 0-42.752 0 29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728 30.592 30.592 0 0 0-42.752 0z"
          ></path>
        </svg>
      </el-icon>
      <div
        v-if="selectedCascadeOptionList.length"
        class="elv-base-cascade-select-clear-btn"
        @click="onClearSelectCascadeOptions"
      >
        <SvgIcon name="group-clear" width="14" height="14" :fill="'#a8abb2'" />
      </div>
    </div>
    <div
      v-else
      class="elv-base-cascade-select-show-info-normal"
      :class="{ 'elv-base-cascade-select-show-info-normal-disabled': props.isDisabled }"
      @click="onChangeCascadeOptionsPopperShowStatus"
    >
      <div class="elv-base-cascade-select-show-info-item">
        <span>{{ selectedCascadeOptionsLabel }}</span>
      </div>
      <SvgIcon
        name="arrow-down-line"
        width="12"
        height="12"
        :style="{ transform: rotate, transition: 'transform 0.3s ease-in-out' }"
        :fill="props.isDisabled ? '#d0d3d6' : '#636B75'"
      />
    </div>
    <el-cascader
      v-bind="$attrs"
      ref="cascadeOptionsSelectRef"
      v-model="cascadeModel"
      :popper-class="`elv-base-cascade-select-popper ${
        props.enabledFilter ? 'elv-base-cascade-select-show-filter-popper' : ''
      }`"
      :options="props.cascadeOptions"
      :props="selectProps"
      filterable
      :placeholder="t('placeholder.search')"
      :popper-append-to-body="false"
      @change="onSelectOptionChange"
      @visible-change="onCascadePanelShowStatusChange"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const rotate = ref('rotate(0deg)')
const isShowFilterInputDom = ref(false)
const selectedCascadeOptionList = ref<any[]>([])
const cascadeOptionsSelectRef = useTemplateRef('cascadeOptionsSelectRef')

const cascadeModel = defineModel<Array<any> | string>('cascadeModel', {
  required: true
})

const props = defineProps({
  isTagType: { type: Boolean, default: true },
  enabledFilter: { type: Boolean, default: true },
  selectSingleGroup: { type: Boolean, default: false },
  cascadeOptions: { type: Array<any>, default: () => [] as Array<any>, required: true },
  cascadeOptionValueName: { type: String, default: 'value' },
  cascadeOptionLabelName: { type: String, default: 'label' },
  cascadeOptionChildName: { type: String, default: 'children' },
  maxSelectCount: { type: Number, default: 2 },
  isMultipleSelect: { type: Boolean, default: true },
  isHighLight: { type: Boolean, default: true },
  isDisabled: { type: Boolean, default: false }
})

const emit = defineEmits(['onChangeSelectCascadeOptions'])

const isMoreSelected = computed(() => selectedCascadeOptionList.value.length > props.maxSelectCount)
const excessSelectCount = computed(() => selectedCascadeOptionList.value.length - props.maxSelectCount)
const additionalSelectedItems = computed(() => selectedCascadeOptionList.value.slice(props.maxSelectCount))
const selectedCascadeOptionsLabel = computed(() =>
  selectedCascadeOptionList.value.length
    ? selectedCascadeOptionList.value.map((item) => item[props.cascadeOptionLabelName]).join(', ')
    : t('common.select')
)

const selectProps = computed(() => ({
  multiple: props.isMultipleSelect,
  emitPath: false,
  value: props.cascadeOptionValueName,
  label: props.cascadeOptionLabelName,
  children: props.cascadeOptionChildName
}))

const showType = computed(() => (isShowFilterInputDom.value && props.enabledFilter ? 'block' : 'none'))

const showMoreTagSelectList = computed(() => {
  if (selectedCascadeOptionList.value.length > props.maxSelectCount) {
    return selectedCascadeOptionList.value.slice(0, props.maxSelectCount)
  }
  return selectedCascadeOptionList.value
})

const filterCascadeOptionBelongSameGroup = (selectList: Array<any>) => {
  if (!selectList.length || !props.selectSingleGroup || !props.isMultipleSelect) return
  const lastSelectId = selectList[selectList.length - 1]
  const parentGroupOfOption = props.cascadeOptions.find((item) =>
    item[props.cascadeOptionChildName].some((subItem: any) => subItem[props.cascadeOptionValueName] === lastSelectId)
  )
  if (!parentGroupOfOption) return

  const filteredSelectList = selectList.filter((item) =>
    parentGroupOfOption[props.cascadeOptionChildName].some(
      (filterItem: any) => filterItem[props.cascadeOptionValueName] === item
    )
  )

  cascadeModel.value = filteredSelectList
}

const onResetSelectCascadeShowOptions = (selectList: any) => {
  if (!selectList) return
  selectedCascadeOptionList.value = props.cascadeOptions.flatMap((category) =>
    category[props.cascadeOptionChildName].filter((item: any) =>
      cascadeModel.value.includes(item[props.cascadeOptionValueName])
    )
  )
}

const onSelectOptionChange = (selectList: any) => {
  filterCascadeOptionBelongSameGroup(selectList)
  emit('onChangeSelectCascadeOptions')
}

const onCascadePanelShowStatusChange = (value: boolean) => {
  isShowFilterInputDom.value = value
  rotate.value = value ? 'rotate(180deg)' : 'rotate(0deg)'
  if (value && !cascadeModel.value?.length) {
    nextTick(() => {
      const menus: any = cascadeOptionsSelectRef.value?.cascaderPanelRef?.menus
      const child1 = menus[0][0]?.children
      const arr: Array<any> = []
      if (menus[0]) arr.push(menus[0])
      if (child1) arr.push(child1)
      if (cascadeOptionsSelectRef.value && cascadeOptionsSelectRef.value.cascaderPanelRef) {
        cascadeOptionsSelectRef.value.cascaderPanelRef.menus = arr
      }
    })
  }
}

const onClearSelectCascadeOptions = (evt: any) => {
  evt?.stopPropagation()
  cascadeModel.value = []
  selectedCascadeOptionList.value = []
}

const onCloseCascadeItem = (item: any) => {
  const index = selectedCascadeOptionList.value.findIndex(
    (i) => i[props.cascadeOptionValueName] === item[props.cascadeOptionValueName]
  )
  if (index !== -1) {
    selectedCascadeOptionList.value.splice(index, 1)
  }
  cascadeModel.value = selectedCascadeOptionList.value.map(
    (selectItem: any) => selectItem[props.cascadeOptionValueName]
  )
}

const onChangeCascadeOptionsPopperShowStatus = () => {
  if (props.isDisabled) return
  cascadeOptionsSelectRef.value?.togglePopperVisible()
}

watch(
  () => cascadeModel.value,
  (newValue) => {
    onResetSelectCascadeShowOptions(newValue)
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-base-cascade-select {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  .elv-base-cascade-select-show-info-normal {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 106px;
    position: relative;

    &.elv-base-cascade-select-show-info-normal-disabled {
      color: #d0d3d6;
    }

    &-item {
      display: flex;
      max-width: calc(100% - 16px);
      align-items: center;
      height: 15px;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-weight: 400;
      color: #636b75;
      position: relative;

      span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    svg {
      margin-left: 4px;
      margin-top: 4px;
    }
  }

  .elv-base-cascade-select-show-info-tags {
    width: 100%;
    height: 44px;
    border: 1px solid #dde1e6;
    box-shadow: 0 1px 3px #00000014;
    padding: 0px 12px;
    border-radius: 4px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.is-focus {
      border-color: #5e85eb;
    }

    &.elv-base-cascade-select-show-info-tags-selected {
      border-color: #5e85eb;
      background-color: #f7f9fe;

      &:hover {
        .elv-base-cascade-select-clear-btn {
          visibility: visible;
        }

        .elv-base-cascade-select-arrow-icon {
          visibility: hidden;
        }
      }
    }

    &.elv-base-cascade-select-show-info-tags-disabled {
      background: #f9fafb;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    }

    .elv-base-cascade-select-show-info-container {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      gap: 6px;
      overflow: hidden;
      position: relative;

      .elv-base-cascade-select-show-info-item {
        height: 24px;
        max-width: 100px;
        border-radius: 3px;
        border: 1px solid #e3e7f1;
        background: #fff;
        padding: 0px 4px 0px 8px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        :deep(.el-tag__content) {
          width: calc(100% - 24px);
          height: 14px;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-weight: 400;
          color: #0e0f11;
        }

        :deep(.el-tag__close) {
          color: #aaafb6;

          &:hover {
            color: #fff;
            background-color: #909399;
          }
        }
      }

      .elv-base-cascade-select-show-info-item-more-count {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-weight: 400;
        color: #0e0f11;
        border: 1px solid #e3e7f1;
        background: #fff;
        border-radius: 3px;
      }

      .elv-base-cascade-select-show-info-container-single-choice {
        max-width: calc(100% - 20px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #0e0f11;
      }
    }

    .elv-base-cascade-select-show-info-item-empty {
      display: flex;
      align-items: center;
      height: 44px;
      font-family: Plus Jakarta Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      color: #838d95;
    }

    .elv-base-cascade-select-clear-btn {
      width: 14px;
      height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      right: 14px;
      border: 1px solid #a8abb2;
      border-radius: 50%;
      visibility: hidden;

      svg {
        fill: #a8abb2;
      }
    }
  }

  :deep(.el-cascader) {
    margin-top: 4px;
    height: 40px;
    width: 650px;

    .el-input {
      height: 40px;
      display: v-bind(showType);
      position: relative;

      &::before {
        content: '';
        display: inline;
        position: absolute;
        background-image: url('@/assets/icons/select-search.svg');
        background-size: cover;
        left: 8px;
        top: 30%;
        width: 14px;
        height: 14px;
        z-index: 2;
      }

      .el-input__wrapper {
        height: 40px;
        width: 651px;
        padding: 0px;
        padding-left: 30px;
        border: 1px solid #e4e7ed;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12) !important;
        border-radius: 4px 4px 0px 0px;

        .el-input__inner {
          height: 40px !important;
        }

        .el-input__suffix {
          display: none;
        }
      }
    }

    .el-cascader__tags {
      .el-tag {
        display: none;
      }

      .el-cascader__search-input {
        height: 40px;
        display: v-bind(showType);
        margin: 0px;
        margin-left: 30px;
      }
    }
  }
}
</style>
<style lang="scss">
.elv-base-cascade-select-popper {
  margin-top: -10px;
  border-radius: 4px;
}

.elv-base-cascade-select-show-filter-popper {
  margin-top: -13px;
  border-radius: 0px 0px 4px 4px;
}

.elv-base-cascade-select-show-filter-popper,
.elv-base-cascade-select-popper {
  font-family: 'Plus Jakarta Sans';
  box-shadow:
    0px 4px 12px rgba(0, 0, 0, 0.12),
    0px -5px 0 rgba(0, 0, 0, 0) !important;
  border-top: 1px solid #dde1e6 !important;
  width: 650px;

  .el-cascader-menu {
    &:first-child {
      width: 179px;

      .el-cascader-node__label {
        font-weight: 600;
        font-size: 12px;
        color: #1e2024;
      }
    }

    &:last-child {
      flex: 1;
    }
  }

  .el-cascader__suggestion-panel {
    .el-cascader__suggestion-item {
      height: 32px;
      color: #1e2024;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;

      &.is-checked {
        background-color: #edf0f3;
      }
    }
  }

  .el-cascader-menu__list {
    padding: 0px;

    li {
      height: 32px;
      padding: 0px 8px;

      &.is-active {
        background-color: #f7f9fe;
      }

      .el-cascader-node__label {
        height: 32px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        color: #606266;
        padding-right: 15px;
      }

      .el-cascader-node__prefix {
        display: none;
      }

      svg {
        path {
          fill: #dce1e6;
        }
      }
    }
  }
}

.elv-base-cascade-select-show-info-container-more-tip {
  max-width: 400px;
  max-height: 200px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
</style>
